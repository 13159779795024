// Don't modify structue, only add data.
import avatar from "../assets/team/avatar.png";

import amal from "../assets/team/amal.jpg";
import sathya from "../assets/team/sathya.jpg";
import julia from "../assets/team/julia.jpeg";


export const alumniData = [
    {
        name: "Dr Julia Priyadarshini Rao",
        designation: 'Clinical Research Fellow',
        from: "Radiation Oncology",
        image: julia,
    },
    {
        name: "Amal Joseph Varghese",
        designation: "Project Assistant",
        from: "Radiation Oncology",
        image: amal,
    },
    {
        name: "Sathya A",
        designation: "Research Coordinator",
        from: "Radiation Oncology",
        image: sathya
    },
    {
        name: 'Ramya Vepuri',
        designation: 'Project Assistant',
        from: 'Radiation Oncology',
        image: avatar
    },
    {
        name: 'Arathy Roy',
        designation: 'Intern',
        from: 'VIT University',
        image: avatar
    },
    {
        name: 'Vimal Nair',
        designation: 'Intern',
        from: 'VIT University',
        image: avatar
    },
    {
        name: 'Sreeram Sreekumar',
        designation: 'Intern',
        from: 'VIT University',
        image: avatar
    }
]